<template>
    <div class="col-12">
        <div class="card">
            <Loader v-model="loading" />
            <HelperDialog v-model="purchaseHelper" header="Buscar Orden de Compra" :headers="purchaseHeaders" :rows="purchases" @selected="selectPurchase" />
            <HelperDialog v-model="supplierHelper" header="Buscar Proveedor" :headers="supplierHeaders" :rows="suppliers" @selected="selectSupplier" />
            <HelperDialog v-model="paymentHelper" header="Buscar Condicion Pago" :headers="paymentHeaders" :rows="payments" @selected="selectPayment" />
            <Panel header="Orden de Compra">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @refresh="refresh" :actions="['new','save','refresh']" />
                <Panel header="Datos Generales">
                    <div class="p-fluid formgrid grid">
                        <FormInputText wrapperClass="field col-3" label="Folio" @search="(purchaseHelper.visible=true)" :search="true" v-model="entity.id" />   
                        <FormCalendar wrapperClass="field col-3" label="Fecha O.C" v-model="entity.purchase_date"/>
                        <FormCalendar wrapperClass="field col-3" label="Fecha Entrega" v-model="entity.deadline"/>
                        <FormCalendar wrapperClass="field col-3" label="Fecha Recepción" v-model="entity.reception_date"/>
                        <FormInputText wrapperClass="field col-2" label="Proveedor" :valid="validate.validations.id_supplier" @search="(supplierHelper.visible=true)" :search="true" v-model="entity.id_supplier" />
                        <FormInputText wrapperClass="field col-2" label="Nombre Proveedor" v-model="entity.supplier"/>
                        <FormInputText wrapperClass="field col-2" label="Centro Costo" :valid="validate.validations.id_cost_center" :search="true" v-model="entity.id_cost_center" />
                        <FormInputText wrapperClass="field col-2" label="Nombre Centro Costo" v-model="entity.cost_center"/>
                        <FormInputText wrapperClass="field col-2" label="Condicion Pago" :valid="validate.validations.id_payment_condition" @search="(paymentHelper.visible=true)" :search="true"  v-model="entity.id_payment_condition" />
                        <FormInputText wrapperClass="field col-2" label="Nombre Condicion Pago" v-model="entity.payment_condition"/>
                        <FormInputText wrapperClass="field col-4" label="Direccion" :valid="validate.validations.address" v-model="entity.address" />
                        <FormInputText wrapperClass="field col-4" label="Colonia" :valid="validate.validations.suburb" v-model="entity.suburb"/>
                        <FormInputText wrapperClass="field col-4" label="Poblacion" :valid="validate.validations.municipality" v-model="entity.municipality"/>
                        <FormInputText wrapperClass="field col-4" label="Contacto"  v-model="entity.contact"/>
                        <FormInputText wrapperClass="field col-4" label="Atencion"  v-model="entity.attention"/>
                        <FormInputText wrapperClass="field col-2" label="Telefono" :valid="validate.validations.phone" v-model="entity.phone"/>
                        <FormInputText wrapperClass="field col-2" label="Correo" :valid="validate.validations.email" v-model="entity.email"/>
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="% IVA" :options="accounts" optionLabel="" optionValue=""  v-model="entity.id_tax"/>
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="Tipo Orden Compra" :options="accounts" optionLabel="" optionValue=""  v-model="entity.id_purchase_order_type" />
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="Etiqueta" :options="accounts" optionLabel="" optionValue=""  v-model="entity.label"/>
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="Tipo Embarque" :options="accounts" optionLabel="" optionValue="" />
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="LAB" :options="accounts" optionLabel="" optionValue=""  v-model="entity.id_lab"/>
                        <FormDropdownComplex :filterFields="['', '']" :labelInOption="''" 
                        :labelInValue="''" :wrapperClass="'field col-4'" label="Moneda" :options="accounts" optionLabel="" optionValue=""  v-model="entity.id_currency"/>
                        <FormInputNumber wrapperClass="field col-4" label="Tipo Cambio" v-model="entity.exchange_rate"/>
                        <FormInputNumber wrapperClass="field col-4" label="% Descuento" prefix="%" v-model="entity.discount"/>
                        <FormInputText wrapperClass="field col-12" label="Observaciones" v-model="entity.observations"/>
                    </div>
                </Panel>
            </Panel>
        </div>
    </div>
</template>

<script>
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from '../../../components/general/FormInputText.vue'
import FormCalendar from '../../../components/general/FormCalendar.vue'
import FormInputNumber from '../../../components/general/FormInputNumber.vue'
import Loader from "../../../components/general/Loader.vue"
import Session from "../../../mixins/sessionMixin";
//import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';
import { PurchaseOrder } from "../../../models/compras/PurchaseOrder";
import {Supplier} from '../../../models/cxp/Supplier';
//import {PaymentCondition} from '../../../models/comercial/PaymentCondition';

import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    Toast,
    ErrorToast,
} from "../../../utilities/General";

export default{
    mixins: [Session],
    props: {
    modal: null,
  },

  data(){
    return{
        loading: false,
        entity: new PurchaseOrder(),
        entities:[],
        purchases:[],
        suppliers:[],
        payments:[],
        purchaseHelper:{
            visible: false
        },
        supplierHelper:{
            visible: false
        },
        paymentHelper:{
            visible: false
        },
        rules: [
                new Rule({ name: "id_supplier" }),
                new Rule({ name: "id_cost_center" }),
                new Rule({ name: "id_payment_condition" }),
                new Rule({ name: "address" }),
                new Rule({ name: "suburb" }),
                new Rule({ name: "municipality" }),
                new Rule({ name: "phone" }),
                new Rule({ name: "email" }),
               /* new Rule({ name: "id_tax" }),
                new Rule({ name: "id_purchase_order_type" }),
                new Rule({ name: "label" }),
                new Rule({ name: "id_lab" }),
                new Rule({ name: "id_currency" }),*/
            ],
        validate: {
                valid: false,
                validations: {
                    id_supplier: null,
                    id_cost_center: null,
                    payment_condition: null,
                    address: null,
                    suburb: null,
                    municipality: null,
                    phone: null,
                    email: null,
                    /*id_tax: null,
                    id_purchase_order_type: null,
                    label: null,
                    id_lab: null,
                    id_currency: null*/
                }
            },
        purchaseHeaders:[
            new HeaderGrid('Folio OC','id'),
            new HeaderGrid('Fecha','purchase_date',{type: "date"}),
            new HeaderGrid('Proveedor','name'),
        ],
        supplierHeaders:[
            new HeaderGrid('Nombre','name'),
            new HeaderGrid('RFC','rfc')
        ],
        paymentHeaders:[
            new HeaderGrid('Nombre','name'),
            new HeaderGrid('Dias','days'),
        ]
        
    }
  },

  components:{BasicFormToolbar,FormInputText,FormInputNumber,Loader,FormCalendar,FormDropdownComplex,HelperDialog},
  created() {
        this.entity = new PurchaseOrder(this.session);
      },
      async mounted() {
        await this.refresh();
      },
      methods:{
        selectPurchase(payload){
            this.entity.id = payload.id;
        },
        selectSupplier(payload){
            this.entity.id_supplier = payload.id;
        },
        selectPayment(payload){
            this.entity.id_payment_condition = payload.id;
        },
        openNew() {
          this.entity = new PurchaseOrder(this.session);
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    this.entity.status = this.status;
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new PurchaseOrder(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
          this.loading = true;
          try {
            if (!this.modal) 
              this.entities = await this.entity.all();
              this.purchases = await new PurchaseOrder(this.session).all();
              this.suppliers = await new Supplier(this.session).all();
              //this.payments = await new PaymentCondition(this.session).all();
              if (this.id) {
              this.entity.id = this.id;
              let entity = await this.entity.retrieve();
              this.entity = fillObject(this.entity, entity);
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        }
      }
}
</script>

<style scoped lang="scss">

</style>