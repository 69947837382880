import {
    Abstract
} from './Abstract';

export class PurchaseOrder extends Abstract{
    constructor(session){
        super('shopping/PurchaseOrder',session);
        this.id_supplier = null;
        this.id_cost_center = null;
        this.id_payment_condition = null;
        this.purchase_date = new Date();
        this.deadline = new Date();
        this.reception_date = null;
        this.id_tax = null;
        this.id_purchase_order_type = null;
        this.label = null;
        this.id_lab = null;
        this.id_currency = null;
        this.exchange_rate = 1.00;
        this.discount = 0.00;
        this.observations = null;
        this.status = null;
    }
}